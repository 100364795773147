.news-page-container {
    padding: 20px;
  }
  
  .header {
    text-align: center;
  }
  
  .grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
  }
  
  .article-box {
    background: #f4f4f4;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .article-box h3 {
    margin-bottom: 10px;
    font-size: 1.25rem;
  }
  .article-box a{
    color:#000;
  }
  .article-box p {
    margin: 5px 0;
  }
  
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
  
  .pagination button {
    background: #333;
    color: white;
    border: none;
    padding: 10px;
    margin: 0 5px;
    cursor: pointer;
  }
  
  .pagination button.active {
    background: #4caf50;
  }
  
  .pagination button:hover {
    background: #555;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .grid-container {
      grid-template-columns: 1fr;
    }
  }
  