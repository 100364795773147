/* Global styling for fonts */

/* Set body font */
body {
  font-family: 'Plus Jakarta Sans', sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: #f8f7f3;
}

/* Set heading font */
h1, h2, h3, h4, h5, h6 {
  font-family: 'DM Serif Text', serif;
  margin: 0;
  padding: 0;
  font-weight: 300;
  text-transform: uppercase;
}
.max-width{max-width: 1400px;
margin:auto;}
.container{
  padding-left: 2rem;
  padding-right: 2rem;
}
.section{
  margin-top: 50px;
}
a{    text-decoration: none;
  color: #d3b674;
}

.content-container{
  
  margin-bottom: 40px;
}