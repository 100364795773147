.insurance-companies-container {
    max-width: 1400px;
    margin: 0 auto;
    padding: 20px;
    text-align: center;
  }
  
  .company-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-top: 20px;
  }
  
  .company-box {
    padding: 20px;
    background-color: #f9f9f9;
    text-align: left;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}
  
  .company-box h3 {
    font-size: 16px;
    margin-bottom: 10px;
    font-weight: 400;
  }
  
  .company-box p {
    font-size: 14px;
    margin-bottom: 10px;
  }
  
  .claim-link {
    display: inline-block;
    margin-top: 10px;
    padding: 10px 15px;
    background-color: #d3b574;
    color: #fff !important;
    text-decoration: none;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .claim-link:hover {
    background-color: #b69d68;
  }
  
  .show-more-btn {
    margin-top: 20px;
    padding: 10px 15px;
    background-color: #d3b574;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  .show-more-btn:hover {
    background-color: #b69d68;
  }
  
  .insurance-text-cont{
    text-align: left;
  }

  .company-box a{
    color:#000;
    text-decoration: none;
  }