/* Basic styling for the navbar */
.navbar {
    background-color: rgb(0 0 0 / 88%) !important;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 100;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .logo img {
    width:49px;
  }
  
  .nav-menu {
    display: flex;
    list-style: none;
  }
  
  .nav-item {
    margin: 0 1rem;
  }
  
  .nav-links {
    text-decoration: none;
    color: white;
    
    font-size: .8rem;
  }
  
  .nav-btn-container {
    margin-left: auto;
  }
  
  .nav-btn {
    background-color: #d3b574;
    color: white;
    border: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: 600;
}
  
  .hamburger {
    display: none;
    flex-direction: column;
    cursor: pointer;
  }
  
  .hamburger .line {
    width: 25px;
    height: 3px;
    background-color: white;
    margin: 3px;
  }
  
  /* Responsive Styles */
  @media (max-width: 768px) {
    .nav-menu {
      display: none;
      flex-direction: column;
    }
  
    .nav-menu.open {
      display: flex;
    }
  
    .hamburger {
      display: flex;
    }
  
    .nav-btn {
      display: none;
    }
  }
  