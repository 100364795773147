.intake-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem;
    background-color: #ffffff;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    border: 1px solid #ddd;
}
  
  .intake-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1400px;
    margin: 0 auto;
  }
  
  .intake-text {
    flex: 1;
    padding-right: 2rem;
  }
  
  .intake-title {
    font-size: 1.5rem;
    font-family: 'DM Serif Text', serif;
  }
  
  .intake-description, .tab-text p {
    font-size: 1.2rem;
    font-family: 'Plus Jakarta Sans', sans-serif;
    margin-bottom: 1.5rem;
  }
  
  .intake-link-button {
    font-size: 1rem;
    color: #fff;
    background-color: #d3b674;
    padding: 0.75rem 1.5rem;
    border-radius: 5px;
    text-decoration: none;
}

  
  .intake-animation {
    flex: 1;
    display: flex;
    justify-content: center;
  }
  
  @media (max-width: 768px) {
    .intake-sub-content {
      flex-direction: column;
      align-items: center;
      text-align: center;
    }
    .intake-inner {
       
        flex-direction: column;
    }
    .text-section {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
    .animation-section {
      display: flex;
      justify-content: center;
    }
  }