.footer {
    background-color: #1a1a1a;
    padding: 20px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
  
  .footer-container {
    max-width: 1400px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .footer-logo img {
    max-width: 50px;
  }
  
  .footer-links ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  
  .footer-links ul li {
    display: inline;
    margin: 0 10px;
  }
  
  .footer-links ul li a {
    color: #ffffff;
    text-decoration: none;
    font-weight: 500;
  }
  
  .footer-links ul li a:hover {
    text-decoration: underline;
  }
  